<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card" v-if="group && group.id">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Group candidate list</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Group candidate list
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createCandidateList()"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;">
                    <i class="fa fa-plus"></i>
                    Add candidate
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <!-- Group Information Section -->
          <div class="card-body">
            <div class="card mb-4 shadow-sm p-4 bg-light group-info">
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="info-block">
                  <h5 class="text-primary mb-1">Group name:</h5>
                  <p class="font-weight-bold mb-0">{{ group.name || "N/A" }}</p>
                </div>
                <div class="info-block">
                  <h5 class="text-primary mb-1">Enroller name:</h5>
                  <p class="font-weight-bold mb-0">{{ group.user_name || "N/A" }}</p>
                </div>
                <div class="info-block">
                  <h5 class="text-primary mb-1">Entity type:</h5>
                  <p class="font-weight-bold mb-0">{{ group.is_group_enrolled ? 'Individual candidate' : 'Group entity' }}</p>
                </div>
              </div>
            </div>

            <!-- Table Section (existing content) -->
            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading">
              </v-skeleton-loader>
              <v-skeleton-loader v-if="loading" type="table-row-divider@25"></v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr>
                  <th>Full name</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in groupCandidates">
                    <td class="px-2">
                      <span class="font-weight-medium">
                        {{ item.full_name }}
                      </span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown size="sm" variant="link"
                                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                    no-caret
                                    right
                                    no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editCandidate(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteCandidate(item.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="groupCandidates.length === 0">
                    <td colspan="4" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <add-candidate-to-group v-if="group && group.id" ref="add-candidate-to-group" :groupId="group.id" :userId="group.user_id" :group="group" @refresh="findGroupDetail"></add-candidate-to-group>
      <create-or-update-candidate-list v-if="group && group.user_id" ref="create-update-candidate-list" :user-id="group.user_id" @refresh="getAllCandidateLists"></create-or-update-candidate-list>
    </div>
  </v-app>
</template>
<script>
import CandidateGroupService from "@/services/candidate/candidate_group/CandidateGroupService";
import CreateOrUpdateCandidateList from "@/view/pages/view/user/general/group/CreateOrUpdateCandidateList.vue";
import AddCandidateToGroup from "@/view/pages/view/user/general/group/AddCandidateToGroup.vue";
const candidateGroup=new CandidateGroupService();
export default {
  components:{CreateOrUpdateCandidateList,AddCandidateToGroup},
  data(){
    return{
      groupCandidates:[],
      group:null,
      groupLoading:false,
      loading:false,
      user:null,
    }
  },
  methods:{
    createCandidateList(){
      if(this.group.is_group_enrolled){
        this.$refs['create-update-candidate-list'].createCandidateGroupList(this.group.id);
      }else{
        this.$refs['add-candidate-to-group'].addCandidate();
      }
    },
    editCandidate(item){
      this.$refs['create-update-candidate-list'].editCandidateGroupList(item,this.group.id)
    },
    findGroupDetail(){
      this.groupLoading=true;
      candidateGroup
          .show(this.groupId)
          .then((res) => {
            this.group=res.data.candidateGroup;
            this.getAllCandidateLists();
          })
          .catch((err) => {

          })
          .finally(() => {
            this.groupLoading=false;
          })
    },
    getAllCandidateLists(){
      this.loading=true;
      candidateGroup
          .getAllGroupCandidateLists(this.group.id,{})
          .then((res) => {
            this.groupCandidates=res.data.groupCandidates;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading=false;
          })
    },
    deleteCandidateList(id){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading=true;
            candidateGroup
                .deleteGroupCandidate(this.group.id,id)
                .then((response) => {
                  this.$snotify.html(`<div class="ameb__alert-success">Candidate deleted from group</div>`);
                  this.getAllGroupCandidates();
                })
                .catch((err) => {
                  this.$snotify.html(`<div class="ameb__alert-error">Unable to delete candidate from this group</div>`);
                })
                .finally(() => {
                  this.loading=false;
                });
          }
        }
      });
    },

  },
  mounted() {
    this.findGroupDetail();
  },
  computed: {
    groupId() {
      return this.$route.params.id;
    },
  }
}
</script>
<style scoped>

.group-info {
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.group-info .info-block {
  margin-right: 20px;
  flex: 1;
  min-width: 200px;
}

.group-info h5 {
  font-size: 1rem;
  margin-bottom: 0.2rem;
  color: #007bff;
}

.group-info p {
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}
</style>
